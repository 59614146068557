import React, { useEffect } from "react"
import Layout from "../components/layout"
import {Container} from "react-bootstrap"
import { checkLoginOrRedirect }  from '../components/login'

export default function NotFound() {
  useEffect(checkLoginOrRedirect, [])

  return (
    <Layout pageName="404" activePage=" " containerClass=" ">
        <Container style={{marginTop: "60px", marginBottom: "40%"}}>
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Container>
    </Layout>
	)
}
